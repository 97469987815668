<template>
  <div>
    <div class="uk-margin-top">
      <div class="uk-card uk-card-default kandang-card">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Kode Siklus
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.cycle_code"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Nama Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.name"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Anak Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.cage_staff"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Pengawas Lapangan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.field_supervisor"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <!-- <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Harga Kontrak
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <div class="uk-form-controls">
                  <input
                    v-model="contractPrice.price_type"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div> -->
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Populasi (ekor)
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <div class="uk-form-controls">
                  <input
                    v-model="data.population"
                    class="uk-input"
                    type="text"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Harga Kontrak
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <div class="uk-width-1 price-field">
                  <input
                    v-model="data.contract_price_code"
                    class="uk-input"
                    type="text"
                    name="contract_price"
                    disabled
                  >
                  <label
                    class="label-price"
                    @click="detailPrice"
                  >Lihat</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-side" />
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Mulai Persiapan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.start_preparation"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Selesai Persiapan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.end_preparation"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Chick In
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.chick_in"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Hari ke-1
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.start_maintenance"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Mulai Panen
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.start_harvest"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Chick Out
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.chick_out"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Mulai Sanitasi
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.sanitation"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Selesai Sanitasi
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="data.end_sanitation"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
      <!--Table Detail Farm -->
      <!-- <TableFarm /> -->
      </div>
    </div>
    <div class="uk-margin-top">
      <SidebarActivity :id-activity="idActivityCage" />
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
// import TableFarm from './table'
import SidebarActivity from './sidebar'
import { dateParanjeCompleteString } from '@/utils/formater'

export default {
  components: {
    // TableFarm,
    SidebarActivity
  },
  props: {
    dataActivity: {
      required: true,
      type: Object
    },
    contractPrice: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      showProduction: process.env.VUE_APP_ENV == 'production' || process.env.VUE_APP_ENV == 'uat',
      images: PREFIX_IMAGE,
      data: {},
      idActivityCage: ''
    }
  },
  computed: {
    ...mapGetters({
      roles: 'user/roles'
    })
  },
  watch: {
    async dataActivity() {
      this.data = {
        cycle_code: this.dataActivity.data.cycle_code,
        name: this.dataActivity.data.name,
        cage_staff: this.dataActivity.data.cage_staff,
        field_supervisor: this.dataActivity.data.field_supervisor,
        contract_price_code: this.dataActivity.data.contract_price_code,
        contract_price_id: this.dataActivity.data.contract_price_id,
        population: this.dataActivity.data.population,
        cycle_count: this.dataActivity.data.cycle_count,
        start_preparation : dateParanjeCompleteString(this.dataActivity.data.start_preparation),
        end_preparation : dateParanjeCompleteString(this.dataActivity.data.end_preparation),
        chick_in : dateParanjeCompleteString(this.dataActivity.data.chick_in),
        start_maintenance : dateParanjeCompleteString(this.dataActivity.data.start_maintenance),
        start_harvest : dateParanjeCompleteString(this.dataActivity.data.start_harvest),
        chick_out : dateParanjeCompleteString(this.dataActivity.data.chick_out),
        sanitation : dateParanjeCompleteString(this.dataActivity.data.sanitation),
        end_sanitation : dateParanjeCompleteString(this.dataActivity.data.end_sanitation)
      }
    }
  },
  async mounted() {
    this.idActivityCage = this.$route.params.id
    this.getRole()
  },
  methods: {
    ...mapActions({
      getRole: 'user/getRole'
    }),
    ...mapMutations({
      setModalDelete: 'user/SET_MODAL_DELETE',
      setModalPrice: 'contractPrice/SET_MODAL_PRICE'
    }),
    async detailPrice() {
      if(this.data.contract_price_id !== ''|| this.formData.contract_price_code !== '') {
        await this.setModalPrice({ data: this.contractPrice, date: this.contractPrice.contract_price_chicken[0].updated_at })
        window.UIkit.modal('#modal-contract-price').show()
      }
    },
    goToBack() {
      window.location.href = '/admin/peternakan'
    },
    goToEdit() {
      this.$router.push(`/admin/peternakan/edit/${this.dataActivity.id}`)
    },
    showDeleteConfirmModal() {
      window.UIkit.modal('#modal-delete-confirm').show()
      this.setModalDelete(this.dataActivity)
    }
  }
}
</script>

<style scoped>
.bottom-side {
  border-top: 1px solid #e0ebe6;
  padding-top: 32px !important;
  margin-top: 32px !important;
}
.price-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #F0F5F3;
  background-color: #F0F5F3;
}
.label-price {
  cursor: pointer;
  margin: 0 12px;
  font-size: 14px;
  color: #049559;
}
</style>
